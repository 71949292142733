<template>
    <div :class="'m-p profile-container ' + ( this.$store.state.profileSideBar ? 'open' : '' )">
       <ManagingRoles />
    </div>
 </template>
 <script>
 import { defineAsyncComponent } from 'vue'
 export default {
 name: 'My Companies',
 components: {
    ManagingRoles: defineAsyncComponent( () => import('@/components/Profile/Companies/ManagingRoles.vue') ),
 },
 }
 </script>